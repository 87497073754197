import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import styled from "styled-components";

const Header = styled.div`
  position: relative;
  h1 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
`;

const ContentContainer = styled.div`
  img {
    margin: 0 auto;
    display: block;
    @media (min-width: 1200px) {
       float: right;
       margin-left: 20px;
       margin-top: 20px;
    }
  }
`;

export const SimplePageTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  featureImage
}) => {
  const PostContent = contentComponent || Content;
  return (
    <>
      <Header>
        <PreviewCompatibleImage
          imageInfo={{ image: featureImage, style: { maxHeight: "50vh" } }}
        />
        <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
          {title}
        </h1>
      </Header>
      <section className="section">
        {helmet || ""}
        <div className="container content">
          <div className="columns">
            <ContentContainer className="column is-10 is-offset-1">
              <PostContent content={content} />
            </ContentContainer>
          </div>
        </div>
      </section>
    </>
  );
};

SimplePageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  helmet: PropTypes.object
};

const SimplePage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SimplePageTemplate
        content={post.html}
        contentComponent={HTMLContent}
        helmet={<Helmet titleTemplate={post.frontmatter.title}></Helmet>}
        title={post.frontmatter.title}
        featureImage={post.frontmatter.featuredimage}
      />
    </Layout>
  );
};

SimplePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
};

export default SimplePage;

export const pageQuery = graphql`
  query SimplePageByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
  }
`;
