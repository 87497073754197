import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

const defaultStyles = {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center center',
};

const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = "", childImageSharp, image, style, imgStyle } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return (
      <Img style={style} fluid={image.childImageSharp.fluid} alt={alt} imgStyle={imgStyle} />
    );
  }

  if (!!childImageSharp) {
    return <Img style={style} fluid={childImageSharp.fluid} alt={alt} imgStyle={imgStyle}/>;
  }

  if (!!image && typeof image === "string")
    return <img style={{...defaultStyles, ...style}} src={image} alt={alt}/>;

  return null;
};

PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    style: PropTypes.object,
    imgStyle: PropTypes.object,
  }).isRequired
};

export default PreviewCompatibleImage;
